var exports = {};
var object = {};
var hasOwnProperty = object.hasOwnProperty;

var merge = function merge(options, defaults) {
  if (!options) {
    return defaults;
  }

  var result = {};

  for (var key in defaults) {
    // `if (defaults.hasOwnProperty(key) { … }` is not needed here, since
    // only recognized option names are used.
    result[key] = hasOwnProperty.call(options, key) ? options[key] : defaults[key];
  }

  return result;
};

var regexAnySingleEscape = /[ -,\.\/:-@\[-\^`\{-~]/;
var regexSingleEscape = /[ -,\.\/:-@\[\]\^`\{-~]/;
var regexAlwaysEscape = /['"\\]/;
var regexExcessiveSpaces = /(^|\\+)?(\\[A-F0-9]{1,6})\x20(?![a-fA-F0-9\x20])/g; // https://mathiasbynens.be/notes/css-escapes#css

var cssesc = function cssesc(string, options) {
  options = merge(options, cssesc.options);

  if (options.quotes != "single" && options.quotes != "double") {
    options.quotes = "single";
  }

  var quote = options.quotes == "double" ? "\"" : "'";
  var isIdentifier = options.isIdentifier;
  var firstChar = string.charAt(0);
  var output = "";
  var counter = 0;
  var length = string.length;

  while (counter < length) {
    var character = string.charAt(counter++);
    var codePoint = character.charCodeAt();
    var value = void 0; // If it’s not a printable ASCII character…

    if (codePoint < 32 || codePoint > 126) {
      if (codePoint >= 55296 && codePoint <= 56319 && counter < length) {
        // It’s a high surrogate, and there is a next character.
        var extra = string.charCodeAt(counter++);

        if ((extra & 64512) == 56320) {
          // next character is low surrogate
          codePoint = ((codePoint & 1023) << 10) + (extra & 1023) + 65536;
        } else {
          // It’s an unmatched surrogate; only append this code unit, in case
          // the next code unit is the high surrogate of a surrogate pair.
          counter--;
        }
      }

      value = "\\" + codePoint.toString(16).toUpperCase() + " ";
    } else {
      if (options.escapeEverything) {
        if (regexAnySingleEscape.test(character)) {
          value = "\\" + character;
        } else {
          value = "\\" + codePoint.toString(16).toUpperCase() + " ";
        }
      } else if (/[\t\n\f\r\x0B]/.test(character)) {
        value = "\\" + codePoint.toString(16).toUpperCase() + " ";
      } else if (character == "\\" || !isIdentifier && (character == "\"" && quote == character || character == "'" && quote == character) || isIdentifier && regexSingleEscape.test(character)) {
        value = "\\" + character;
      } else {
        value = character;
      }
    }

    output += value;
  }

  if (isIdentifier) {
    if (/^-[-\d]/.test(output)) {
      output = "\\-" + output.slice(1);
    } else if (/\d/.test(firstChar)) {
      output = "\\3" + firstChar + " " + output.slice(1);
    }
  } // Remove spaces after `\HEX` escapes that are not followed by a hex digit,
  // since they’re redundant. Note that this is only possible if the escape
  // sequence isn’t preceded by an odd number of backslashes.


  output = output.replace(regexExcessiveSpaces, function ($0, $1, $2) {
    if ($1 && $1.length % 2) {
      // It’s not safe to remove the space, so don’t.
      return $0;
    } // Strip the space.


    return ($1 || "") + $2;
  });

  if (!isIdentifier && options.wrap) {
    return quote + output + quote;
  }

  return output;
}; // Expose default options (so they can be overridden globally).


cssesc.options = {
  "escapeEverything": false,
  "isIdentifier": false,
  "quotes": "single",
  "wrap": false
};
cssesc.version = "3.0.0";
exports = cssesc;
export default exports;